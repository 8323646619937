import React from 'react';
import { CSSTransition } from 'react-transition-group';
import classnames from 'classnames';

import LoadingIndicator from './LoadingIndicator';

import style from './Loading.sass';

export interface Props {
  fixed?: boolean;
  visible: boolean;
  center?: boolean;
  overlay?: boolean;
  className?: string;
}

export default class Loading extends React.PureComponent<Props> {
  static Indicator = LoadingIndicator;

  render() {
    const { fixed, visible, center, overlay, className, children } = this.props;

    return (
      <CSSTransition
        timeout={300}
        in={visible}
        mountOnEnter
        unmountOnExit
        appear
        classNames={{
          enterActive: style.visible,
          enterDone: style.visible
        }}
      >
        <div
          className={classnames(
            style.root,
            {
              [style.overlay]: overlay,
              [style.center]: center,
              [style.fixed]: fixed
            },
            className
          )}
        >
          <div className={style.content}>{children}</div>
        </div>
      </CSSTransition>
    );
  }
}
